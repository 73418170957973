.main1 {
  display: flex;
  margin-left: 100px;
}
.heading1 {
  margin-bottom: 20px;
  border: 1px solid #ccc; 
  width: 100%;
  max-width: 600px;
  margin: 30px;
}

.location-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;/* Add border */
  padding: 10px; /* Add padding */
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #16debf;
  margin-right: 10px;
}

.icon {
  color: white;
  font-size: 20px;
}

.paragraph {
  margin: 0;
}

.paragraph span {
  color: #90EE90;
  font-weight: bold;
}

.form-container {
  width: 100% ;
  max-width: 500px;
  margin-bottom: 20px;
  margin: 30px;
  padding: 50px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
}
.form {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.form-field {
  margin-bottom: 20px;
}

label {
  display: block;
  font-weight: bold;
}

input,
textarea {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

textarea {
  height: 100px;
}

button[type="submit"] {
  background-color: #16debf;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #45a049;
}


@media (max-width: 768px) {
  .main1 {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .heading1 {
    width: 40%;
    margin-bottom: 0;
  }
  .location-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .icon-wrapper {
    margin-bottom: 10px;
  }

  .form-container {
    max-width: 100%;
  }
}
