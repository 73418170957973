.footer {
  border: 1px solid black;
  background-color: #16debf;
  padding: 20px;
}

.footer-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.footer-list ul {
  list-style-type: none;
  color: white;
  padding: 0;
  margin: 0;
  width: 25%;
}

.footer-list li {
  margin-bottom: 5px;
}
.nav-links1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 0;
  margin-left: 600px;
  display: flex;
  justify-content: center;
}

.nav-links1 li {
  /* display: inline; */
  margin: 0 10px;
}

.nav-links1 li a {
  text-decoration: none;
  color: white;
}

.nav-links1 li a:hover {
  text-decoration: underline;
}
.social-icons {
  display: flex;
  flex-direction: column;
}

.social-icons a {
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .footer-list {
    flex-wrap: wrap;
  }

  .footer-list ul {
    margin-bottom: 10px;
  }
}
