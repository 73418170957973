.nav-links {
  display: flex;
  justify-content: space-around;
  margin-right: 100px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-links li {
  list-style: none;
  margin: 10px 0;
}

.nav-links li a {
  text-decoration: none;
  color: black;
}

.nav-open {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-open .nav-links {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-links li a:hover {
  color: #16DEBF;
}

.nav-links li:last-child {
  margin-right: 0;
}

.toggle-button {
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #000;
  font-size: 24px;
}

.toggle-button:hover {
  color: #16debf;
}

@media (max-width: 576px) {
  .toggle-button {
    display: flex;
  }

  .nav-links {
    display: none;
  }

  .nav-links.nav-open {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .nav-links li {
    margin: 10px 0;
  }
}

@media (min-width: 577px) {
  .container {
    flex-direction: row;
    justify-content: space-around;
    display: flex;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
    height: 5%;
    justify-content: space-around;
  }
}
  .image-container {
    display: flex;
    margin-left: 100px;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .heading {
    justify-content: end;
    justify-content: flex-end;
    text-align: end;
  }

  .nav-links {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-right: 100px;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .nav-links li {
    list-style: none;
    justify-content: center;
    margin: 10px 0;
  }

  .nav-links li a {
    text-decoration: none;
    color: black;
  }

  .nav-open {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .nav-open .nav-links {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .nav-links li a:hover {
    color: #16DEBF;
  }

  .nav-links li:last-child {
    margin-right: 0;
  }

  .toggle-button {
    display: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #000;
    font-size: 24px;
  }

  .toggle-button:hover {
    color: #16debf;
  }

  @media (max-width: 576px) {
    .toggle-button {
      display: flex;
    }

    .nav-links {
      display: none;
    }

    .nav-links.nav-open {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
    }

    .nav-links li {
      margin: 10px 0;
    }
  }

  @media (min-width: 577px) {
    .container {
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
    }

    .image-container {
      margin-bottom: 0;
    }

    .content-container {
      text-align: left;
    }

    .nav-links {
      display: flex;
    }

    .nav-links li {
      margin-bottom: 0;
      margin-right: 10px;
    }
  }

  @media (min-width: 768px) {
    .container {
      padding: 40px;
    }

    .image-container {
      margin-right: 30px;
    }

    .heading {
      margin-bottom: 0;
    }
  }

  @media (min-width: 992px) {
    .container {
      padding: 60px;
    }
  }

  @media (min-width: 1200px) {
    .container {
      padding: 20px;
    }

    .responsive_nav {
      transform: translateY(100vh);
    }

    .nav-close-btn {
      position: absolute;
      top: 2rem;
      right: 2rem;
    }
  }