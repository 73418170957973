/* CSS file */

.slider-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .full-size-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  