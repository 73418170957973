.main {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  
  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: full;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    animation: backgroundAnimation 20s infinite linear;
  }
  
  @keyframes backgroundAnimation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  