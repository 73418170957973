.about-img {
    width: 100%;
  }
  
  .container1 {
    width: 100%;
    flex-wrap: wrap;
    height: 70px ;
    background-color: grey;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;
   
  }
  .about-button{
    height: 70px !important;
  }
.btn{
    width: 20%;
    margin: 0 10px;
    flex: 1 1 100%;
    text-align: center;
    border-radius: 20px;
    height: 70px;
}
  
  .img {
    width: 100%;
  }
  
  .about-button {
    width: 100%;
    height: 30px;
    background-color: gray;
    justify-content: space-between;
    color: black;
    border: none;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;
    padding: 10px 20px;
    font-size: 14px;
  }

  
  .about-button:hover {
    background-color: #16DEBF;
  }
  
  .about-text {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 60%;
    margin: 20px auto;
    text-align: start;
  }
  
  .list {
    font-size: 17px;
    display: flex;
    justify-content: space-around;
  }
  
  .container2 {
    display: flex;
    justify-content: center;
  }
  
  .circle-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px;
  }
  
  .circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 5px solid gray;
    color: white;
    background-color: #16DEBF;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .text {
    font-size: 18px;
    font-weight: bold;
  }
  
  .name {
    margin-top: 5px;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .about-container {
      padding: 10px;
    }
  
    .container1 {
      flex-direction: column;
      align-items: center;
      margin: 10px 0;
      flex-wrap: wrap;
      justify-content: center;
    }
    .btn {
      margin: 10px 0;
    }
  
    .about-button {
      width: 100%;
      margin-bottom: 5px;
    }
  
    .about-text {
      margin: 10px auto;
      width: 90%;
    }
  
    .list {
        flex-direction: column;
        align-items: center;
      }
    
      ul {
        margin-bottom: 10px;
      }
  
    .underline-heading {
      margin: 20px 0;
    }
  
    .container2 {
        flex-direction: column;
        align-items: center;
      }
    
      .circle-wrapper {
        margin: 20px;
      }
  }
  @media (min-width: 576px) {
  .container1 {
    flex-wrap: nowrap;
  }

  .btn {
    flex: 0 0 auto;
    margin: 5px;
  }
}
  